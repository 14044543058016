<template>
  <el-form class="ui-form" v-on="$listeners" v-bind="{ ...$attrs }" @submit.native.prevent>
    <slot />
  </el-form>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>

</style>
