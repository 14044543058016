import socket from '@/utils/socket.js'
import { removeToken, getToken } from '@/utils/auth'
import { moreUser } from '@/api/index'
import { lonlatSliceEnd, lonlatSliceLen } from './constant'

const chinaPolygonConfig = {
  // lineColor: '#34495e',
  lineWidth: 0,
  // polygonFill: 'rgb(135,196,240)',
  polygonOpacity: 0,
}
const ChinaPolygon = [
  [120.5084784764125, 53.410282609960525],
  [124.48653207552942, 53.867145516211],
  [126.28225510128382, 52.871210929914604],
  [128.20646316230767, 49.86796811023186],
  [131.0302349249929, 48.99174935548851],
  [131.47921891851263, 48.05676548598127],
  [135.32825307451677, 48.90758570356236],
  [134.87812247067967, 47.060458834866154],
  [133.31278250501555, 44.893291451204846],
  [131.64492425795066, 44.6652405847721],
  [131.51649862112487, 42.764032784126414],
  [130.48980323839288, 42.196037321826175],
  [129.20653779425803, 42.100842281670936],
  [128.11532340301156, 41.092773875237015],
  [126.89579329274098, 41.43032026189634],
  [124.77905669054005, 39.87164113778994],
  [123.4960484006671, 39.17839621142606],
  [123.1419868405124, 38.89139746524835],
  [121.12722480251344, 38.68987629222738],
  [122.8734750818589, 37.61269869075827],
  [122.46181301632006, 36.56589518287482],
  [120.03866513734899, 34.7860032383251],
  [123.2919566316292, 30.803424169994724],
  [122.50025964648127, 28.045927117855463],
  [120.03968895653804, 24.739228047266934],
  [115.23308040720782, 21.97814875922069],
  [111.01740790047297, 21.026439795973754],
  [111.46669662153865, 19.176091163462907],
  [110.19364649340801, 17.922347039317078],
  [108.19758362241758, 18.08839812300481],
  [108.02308674311365, 19.623119893525008],
  [109.5698785688682, 20.419500954717034],
  [108.57165325713885, 21.096087890165876],
  [108.19773491549216, 21.375421698652005],
  [106.7017823923366, 21.85928947820967],
  [106.09452230021256, 22.702622605818394],
  [105.25884682523011, 23.157239809524253],
  [102.31988115716408, 22.002186949347266],
  [101.79252861063229, 20.856491087985045],
  [98.97915425714625, 21.838972448913314],
  [97.2211754146629, 24.185533863835175],
  [96.95730303788947, 25.541388093507702],
  [97.83632315864736, 27.11688780551264],
  [91.50918436069446, 26.567697814990527],
  [85.97284735038858, 27.6633006592642],
  [80.43585650317006, 30.05101282127685],
  [77.974990389907, 31.636356061423832],
  [77.88707000155814, 32.678243407693024],
  [78.23859385462576, 33.709021630447324],
  [76.65692703764681, 35.44729814884229],
  [74.4470423638323, 36.95348910755438],
  [74.05935173126069, 37.50545810383848],
  [74.69619352906128, 38.42290499429742],
  [73.47782661689506, 38.40129081851971],
  [73.45025571924462, 39.5224450385418],
  [74.39178638457405, 40.26682773028962],
  [75.83235997651947, 40.58318459265418],
  [76.18058369586264, 40.53148452704593],
  [76.88261543771952, 41.147279929436856],
  [79.61124607219124, 42.30311920754868],
  [81.72164016261699, 45.47038931019423],
  [88.22561910564765, 49.048972188232455],
  [91.91516856013793, 46.02257560160795],
  [96.48480103581464, 43.39974896767959],
  [99.82552004053335, 42.49849785484353],
  [107.2122797406846, 42.36900115403073],
  [113.80608652629027, 45.16206531370458],
  [117.84889754885971, 47.11159545008595],
  [115.56374039110361, 47.94275515624712],
  [116.17938869784454, 49.67968312142005],
  [117.93720836641411, 49.90638211204652],
  [120.22203010928642, 51.79345651307883],
  [120.04680836818994, 52.76186742112569],
  [120.5084784764125, 53.410282609960525],
]

export default {
  async created() {
    this.timeStrHandler()
    if (location.pathname !== '/login') {
      const token = await getToken()
      if (token) {
        $app.userFetch()
        socket(this).then(($socket) => {
          $socket.add(this.messageHandler)
          window.$app.$socket = $socket
        })
        window.addEventListener('beforeunload', () => {
          $app.$socket.close()
          sessionStorage.clear()
          this.exitCall()
        })
      } else {
        $app.logout()
      }
    }
  },
  computed: {
    pool() {
      return this.$store.state.dashboard.notification.pool
    },
    room() {
      return this.$store.state.addressBook.call.room
    },
  },
  watch: {
    pool() {
      this.checkPool()
    },
  },
  data() {
    return {
      timeStr: '',
      timeStr2: '',
      date: new Date(),
      weekMap: ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
    }
  },
  methods: {
    fetchSessionList() {
      let method = 'Session'
      let type = 'List'
      let data = {
        uid: this.userInfo.uid,
        page: 1,
      }
      $app.$socket.query({ method, type, data }).then((msg) => {
        if (Array.isArray(msg.data.sessions)) {
          this.$store.commit(
            'addressBook/SET_SESSIONS',
            msg.data.sessions
              .filter((e) => e.updateTime)
              .sort(function (a, b) {
                return new Date(b.updateTime).getTime() - new Date(a.updateTime).getTime()
              }),
          )
        }
      })
    },
    loadZLMRTCClient() {
      if (!window.ZLMRTCClient) {
        return import('ZLMRTCClient').then((ZLMRTCClient) => {
          return (window.ZLMRTCClient = ZLMRTCClient)
        })
      } else {
        return window.ZLMRTCClient
      }
    },
    timeStrHandler() {
      var dt = new Date()
      var y = dt.getFullYear()
      var mt = ('0' + (dt.getMonth() + 1)).slice(-2)
      var day = ('0' + dt.getDate()).slice(-2)
      var h = ('0' + dt.getHours()).slice(-2)
      var m = ('0' + dt.getMinutes()).slice(-2)
      var s = ('0' + dt.getSeconds()).slice(-2)
      this.timeStr =
        y +
        '/' +
        mt +
        '/' +
        day +
        `&nbsp;${this.weekMap[dt.getUTCDay()]}&nbsp;` +
        h +
        ':' +
        m +
        ':' +
        s
      this.timeStr2 = y + '/' + mt + '/' + day + ` ` + h + ':' + m + ':' + s
      this.date = dt
      setTimeout(() => {
        this.timeStrHandler()
      }, 100)
    },
    async messageHandler(msg) {
      let method = msg.method

      method === 'PUSH' && this.pushHandler(msg.data)
      method === 'SYSTEMCTL' && this.sysHandler(msg.data)
    },
    enableVideo(enable) {
      this.$store.state.addressBook.call.room.callMap[this.uid].video = enable

      this.$store.state.addressBook.call.room.localStream.getTracks()[1].enabled = enable

      let method = 'AVCall'
      let type = 'EnableVideo'

      let data = {
        avId: this.$store.state.addressBook.call.room.callMap[this.uid].avId,
        streamId: this.$store.state.addressBook.call.room.callMap[this.uid].streamId,
        uid: this.userInfo.uid,
        enable: enable,
      }
      $app.$socket.query({ method, type, data })
    },
    enableAudio(uid, enable) {
      if (uid != this.userInfo.uid) {
        return
      }

      let find = this.$copy(this.$store.state.addressBook.call.room.callMap[this.uid])
      find.audio = enable
      this.$store.state.addressBook.call.room.callMap = Object.assign(
        {},
        this.$store.state.addressBook.call.room.callMap,
        {
          [this.uid]: find,
        },
      )

      this.$store.state.addressBook.call.room.localStream.getTracks()[0].enabled = enable

      let method = 'AVCall'
      let type = 'EnableAudio'

      let data = {
        avId: this.$store.state.addressBook.call.room.callMap[this.uid].avId,
        streamId: this.$store.state.addressBook.call.room.callMap[this.uid].streamId,
        uid: this.userInfo.uid,
        enable: enable,
      }
      $app.$socket.query({ method, type, data })
    },

    exitRoomHandler(data, _call) {
      let callList = []
      _call.room.callList.forEach((uid) => {
        if (uid != data.uid) {
          callList.push(uid)
        }
      })
      _call.room.callList = callList
      delete _call.room.callMap[data.uid]
      _call.room.callMap = Object.assign({}, _call.room.callMap)

      let count = 0
      Object.keys(_call.room.callMap).forEach((uid) => {
        if (!_call.room.callMap[uid].isReject) {
          count++
        }
      })
      if (count <= 1) {
        this.exitCall()
      }
    },
    async sysHandler(data) {
      switch (data.type) {
        case 'CPU':
          let system = data.system
          if (system) {
            if (system.cpu > 0.45) {
              this.addAlarm({
                content: 'cpu使用率过高，' + '使用率：' + (system.cpu * 100).toFixed(0) + '%',
                time: 'cpu_' + Date.now(),
                level: system.cpu > 0.7 ? '2' : '1',
              })
            } else {
              this.addAlarm({
                content: 'cpu使用率：' + (system.cpu * 100).toFixed(0) + '%',
                time: 'cpu_' + Date.now(),
                level: '0',
              })
            }

            if (system.mem > 0.55) {
              this.addAlarm({
                content: '内存使用率过高，使用率：' + (system.mem * 100).toFixed(0) + '%',
                time: 'mem_' + Date.now(),
                level: system.mem > 0.8 ? '3' : '1',
              })
            } else {
              this.addAlarm({
                content: '内存使用率：' + (system.mem * 100).toFixed(0) + '%',
                time: 'mem_' + Date.now(),
                level: '0',
              })
            }

            if (system.disks && system.disks.length) {
              let use = 0
              let free = 0
              let all = 0
              system.disks.forEach((item) => {
                use += item.use
                free += item.free
              })
              all = use + free
              let p = use / all
              if (p > 0.7) {
                this.addAlarm({
                  content: '硬盘容量不足，已使用：' + (p * 100).toFixed(0) + '%',
                  time: 'disk_' + Date.now(),
                  level: p > 0.9 ? '3' : '1',
                })
              } else {
                this.addAlarm({
                  content: '硬盘已使用：' + (p * 100).toFixed(0) + '%',
                  time: 'disk_' + Date.now(),
                  level: '0',
                })
              }
            }
          }

          break
        default:
          break
      }
    },
    async pushHandler(data) {
      let _call = this.$store.state.addressBook.call

      if (data.type === 'Calling2') {
        data.type = 'Calling'
      } else if (data.type === 'OffLine') {
        if (_call.room && _call.room.avId == data.avId && _call.room.callMap[data.uid]) {
          this.exitRoomHandler(data, _call)
        }
        return
      }
      switch (data.type) {
        case 'PointCloudBackPass':
          if (data.toUid == $app.userInfo.uid) {
            $app.$emit('PointCloudBackPass', data)
          }
          break
        case 'RealTimePublish':
          let [lng, lat] = window.gcj02towgs84([data.longitude, data.latitude])

          data.longitude = Number(data.longitude)
          data.latitude = Number(data.latitude)
          data.position = [data.longitude, data.latitude]
          data.key = lng + ',' + lat
          $app.$emit('push_RealTimePublish', data)
          break
        case 'Accepted':
          if (data.uid && data.streamId) {
            $app.$emit('live_call_accept', data)
          }
          break
        case 'BusyLine':
          if (data.uid == this.userInfo.uid) {
            removeToken()
            $app.MessageBox.alert('该账号已在别处登录，将自动登出', {
              confirmButtonText: '确定',
              type: 'warning',
              showClose: false,
              closeOnClickModal: false,
            })
              .then(() => {
                $app.logout()
              })
              .catch(() => {
                $app.logout()
              })
          }
          break
        case 'SYSTEMCTL':
          let system = data.system
          if (system) {
            if (system.cpu > 0.45) {
              this.addAlarm({
                content: 'cpu使用率过高，' + '使用率：' + (system.cpu * 100).toFixed(0) + '%',
                time: 'cpu_' + Date.now(),
                level: system.cpu > 0.7 ? '2' : '1',
              })
            } else {
              this.addAlarm({
                content: 'cpu使用率：' + (system.cpu * 100).toFixed(0) + '%',
                time: 'cpu_' + Date.now(),
                level: '0',
              })
            }

            if (system.mem > 0.55) {
              this.addAlarm({
                content: '内存使用率过高，使用率：' + (system.mem * 100).toFixed(0) + '%',
                time: 'mem_' + Date.now(),
                level: system.mem > 0.8 ? '3' : '1',
              })
            } else {
              this.addAlarm({
                content: '内存使用率：' + (system.mem * 100).toFixed(0) + '%',
                time: 'mem_' + Date.now(),
                level: '0',
              })
            }

            if (system.disks && system.disks.length) {
              let use = 0
              let free = 0
              let all = 0
              system.disks.forEach((item) => {
                use += item.use
                free += item.free
              })
              all = use + free
              let p = use / all
              if (p > 0.7) {
                this.addAlarm({
                  content: '硬盘容量不足，已使用：' + (p * 100).toFixed(0) + '%',
                  time: 'disk_' + Date.now(),
                  level: p > 0.9 ? '3' : '1',
                })
              } else {
                this.addAlarm({
                  content: '硬盘已使用：' + (p * 100).toFixed(0) + '%',
                  time: 'disk_' + Date.now(),
                  level: '0',
                })
              }
            }
          }

          break
        case 'OnLine':
          this.$store.state.dashboard.onlineNum = data.onLine
          break
        case 'Text':
          let sessionId =
            this.$store.state.addressBook.itemInfo && this.$store.state.addressBook.itemInfo.id
          if (!sessionId) {
            return
          }
          if (data.sessionId == sessionId) {
            if (data.message.fromUser.uid !== this.userInfo.uid) {
              let find = this.$store.state.addressBook.chatList.find(
                (e) => e.id === data.message.id,
              )
              if (!find) {
                this.$store.state.addressBook.chatList.push({
                  avatar: data.message.fromUser.avatar,
                  createTime: data.message.createTime,
                  fromUid: data.message.fromUser.uid,
                  message: data.message.text,
                  name: data.message.fromUser.name,
                  sessionId: data.sessionId,
                  id: data.message.id,
                })
              }
            }
          } else if (!$app.$store.state.addressBook.sessions.find((e) => e.id == data.sessionId)) {
            $app.fetchSessionList()
          }
          break
        case 'Reject': // 有用户拒绝通话
          // {
          //   "type": "Reject",
          //   "avId": "12345678765432", # 创建通话时返回
          //   "uid": 16
          // }
          if (!_call.room) {
            return
          }
          if (_call.room.avId != data.avId) {
            return
          }
          var find = _call.room.callMap[data.uid]
          if (find) {
            find.streamId = ''
            find.isReject = true
            _call.room.callMap = Object.assign({}, _call.room.callMap, {
              [data.uid]: find,
            })
          }
          break
        case 'EnableVideo':
          if (!_call.room) {
            return
          }
          if (_call.room.avId != data.avId) {
            return
          }
          var find = _call.room.callMap[data.uid]
          if (find) {
            if (!!find.video === !!data.enable) {
              return
            }
            find.video = !!data.enable
            _call.room.callMap = Object.assign({}, _call.room.callMap, {
              [data.uid]: find,
            })
          }
          break
        case 'EnableAudio':
          if (!_call.room) {
            return
          }
          if (_call.room.avId != data.avId) {
            return
          }
          var find = _call.room.callMap[data.uid]
          if (find) {
            if (!!find.audio === !!data.enable) {
              return
            }
            find.audio = data.enable
            _call.room.callMap = Object.assign({}, _call.room.callMap, {
              [data.uid]: find,
            })
          }
          break
        case 'Joined': // 有用户加入通话
          // "type": "Joined",
          // "avId": "12345678765432", # 创建通话时返回
          // "uid": 16,
          // "avatar": "",
          // "name": "admin",
          // "video": true,
          // "audio": true,
          // "streamId": "456796534567865_234u45678876543",
          if (!_call.room) {
            return
          }
          if (_call.room.avId != data.avId) {
            return
          }
          var find = _call.room.callMap[data.from.uid]

          if (!this.usersMap[data.from.uid]) {
            await $app.findUsers([data.from.uid])
          }
          if (find) {
            find.streamId = data.from.streamId
            find.isReject = false
          } else {
            _call.room.callList = [].concat(_call.room.callList, [data.from.uid])
            _call.room.callMap = Object.assign({}, _call.room.callMap, {
              [data.from.uid]: new _call.CallMember({
                type: 'Join',
                avId: data.avId,
                uid: data.from.uid,
                video: data.video,
                audio: data.audio,
                streamId: data.from.streamId,
              }),
            })
          }
          break
        case 'Exit': // 有用户退出通话
          if (!_call.room) {
            return
          }
          if (_call.room.avId != data.avId) {
            return
          }
          var find = _call.room.callMap[data.uid]
          if (find) {
            this.exitRoomHandler(data, _call)
          }
          break
        case 'Calling': // 被叫
          {
            // var data = {
            //   type: 'Calling',
            //   avId: '12345678765432',
            //   title: '标题',
            //   video: true,
            //   audio: true,
            //   isFuture: false,
            //   startTime: '开始时间（预约用）2023-01-01 00:00:00',
            //   endTime: '结束时间（预约用）2023-01-01 00:30:00',
            //   member: [15, 14, 16, 17, 18],
            //   fromUser: {
            //     uid: 15,
            //     avatar: '',
            //     name: 'admin',
            //     streamId: '456796534567865_234u45678876543',
            //   },
            //   createTime: '',
            // }
            let method = 'AVCall'
            let type = ''
            let avId = data.avId

            let title = data.avCall.title
            let video = data.avCall.video
            let audio = data.avCall.audio
            // let member = data.avCall.member
            let isFuture = false
            let startTime = ''
            let endTime = ''

            let uid = this.userInfo.uid
            let room = _call.room && _call.room.callMap[uid]
            let creatorUid = data.fromUser.uid
            if (uid == creatorUid) {
              return
            }

            if (room && room.avId == avId) {
              return
            }

            if (room && room.avId != avId) {
              let count = 0
              Object.keys(_call.room.callMap).forEach((uid) => {
                if (!_call.room.callMap[uid].isReject) {
                  count++
                }
              })
              if (count > 1) {
                type = 'Reject'
              } else {
                this.exitCall()
              }
            }

            if (!type) {
              let wait = 30000
              await new Promise((resolve) => {
                setTimeout(() => {
                  if (type) {
                    return
                  }
                  type = 'Reject'
                  $app.MessageBox.close()
                  resolve()
                }, wait)
                $app.MessageBox.confirm('确定接听吗?', '你被呼叫了', {
                  type: 'info',
                  showClose: false,
                  closeOnClickModal: false,
                  closeOnPressEscape: false,
                })
                  .then(() => {
                    type = 'Join'
                  })
                  .catch(() => {
                    type = 'Reject'
                  })
                  .finally(() => {
                    resolve()
                  })
              })
            }

            if (type === 'Join') {
              _call.room = new _call.CallRoom({
                avId: avId,
                title: title,
                video: video,
                audio: audio,
                isFuture: isFuture,
                startTime: startTime,
                endTime: endTime,
                type: 'Join',
              })
              this.$instance.chat.openDialog()
            } else if (type === 'Reject') {
              let data = {
                avId: avId,
                uid: uid,
              }
              $app.$socket.query({ method, type, data })
            }
          }
          break
        default:
          break
      }
    },
    checkPool() {
      if (this.pool.length) {
        if (this.$store.state.dashboard.notification.msgListAvailable) {
          this.$store.state.dashboard.notification.msgListAvailable = false
          setTimeout(() => {
            this.$store.state.dashboard.notification.msgListAvailable = true
            this.checkPool()
          }, 1000)
          let poolItem = this.pool.shift()
          this.$store.state.dashboard.notification.msgList.unshift(poolItem)
          this.$store.state.dashboard.notification.msgList.splice(1000)
          setTimeout(() => {
            this.$store.state.dashboard.notification.msgList.unshift({ id: 0 })
          }, 100)
        }
      }
    },
    addAlarm({ content, deviceId, time, level, audio }) {
      let find =
        this.$store.state.dashboard.notification.msgList.find((e) => e.id === time) ||
        this.$store.state.dashboard.notification.pool.find((e) => e.id === time)
      if (find) {
        return
      }
      if (audio) {
        window.$app.$libs.webSpeech.speak(content)
      }
      let item = {
        id: time,
        time: this.$root.timeStr2,
        level: level ? level : '3',
        content: content,
        detail: '详情',
        deviceId: deviceId,
      }
      item.isRead = false
      this.$store.state.dashboard.notification.pool.push(item)
    },
    exitCall() {
      if (this.$store.state.addressBook.call.room && this.$store.state.addressBook.call.room.avId) {
        let method = 'AVCall'
        let type = 'Exit'
        let data = {
          uid: this.userInfo.uid,
          avId: this.$store.state.addressBook.call.room.avId,
        }
        $app.$socket.query({ method, type, data })
      }
      this.$store.state.addressBook.call.room = null
    },
    async toCallPre(userItem, isAudio) {
      let member = [this.userInfo.uid, userItem.id]
      let title = `${this.userInfo.name},${userItem.name}`
      let video = isAudio ? false : true
      let audio = isAudio ? true : false
      let isFuture = false
      let startTime = ''
      let endTime = ''
      let res = await this.call({ title, video, audio, isFuture, startTime, endTime, member })
      res && this.$instance.chat.openDialog()
      return res
    },
    toCall(userItem, isAudio) {
      return $app.MessageBox.confirm('确定呼叫' + userItem.name + '吗?', '提示', {
        type: 'info',
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
      })
        .then(async () => {
          return this.toCallPre(userItem, isAudio)
        })
        .catch(() => false)
    },
    /**
     * 发起实时通话
     * @param {Object} option - 通话选项
     * @param {number} option.uid - 通话接收方的用户ID
     * @param {boolean} [silence=false] - 是否静默模式，默认为false
     * @returns {Promise} - 返回一个Promise，包含通话结果
     */
    async liveCall(option, silence = false) {
      // 设置请求方法为'Live'
      let method = 'Live'
      // 设置请求类型为'Invitation'
      let type = 'Invitation'
      // 构建请求数据
      let data = {
        // 当前用户的ID
        uid: this.userInfo.uid,
        // 通话接收方的用户ID
        toUid: option.uid,
        // 默认开启视频
        video: true,
        // 默认开启音频
        audio: true,
      }

      // 发送请求并处理响应
      return (
        $app.$socket
          // 发送查询请求
          .query({ method, type, data })
          // 处理成功响应
          .then((msg) => {
            // 如果响应数据存在且用户不在线，并且有错误消息且非静默模式
            if (msg.data && !msg.data.online && msg.data.message && !silence) {
              // 显示错误消息
              $app.$message.error(msg.data.message)
            }
            // 返回响应数据
            return msg
          })
          // 处理失败响应
          .catch((msg) => {
            // 如果响应数据存在且有错误消息且非静默模式
            if (msg.data && msg.data.message && !silence) {
              // 显示错误消息
              $app.$message.error(msg.data.message)
            }
            // 返回错误信息
            return msg
          })
      )
    },
    async call({ title, video, audio, isFuture, startTime, endTime, member }) {
      this.exitCall()
      let data = {
        uid: this.userInfo.uid,
        title: title,
        video: video,
        audio: audio,
        isFuture: isFuture || false,
        startTime: startTime || '',
        endTime: endTime || '',
        member: member,
      }

      let _ = {}
      let _call = this.$store.state.addressBook.call

      _.type = 'Calling'
      _.title = data.title
      _.video = data.video
      _.audio = data.audio
      _.isFuture = data.isFuture
      _.startTime = data.startTime
      _.endTime = data.endTime
      _.callList = this.$copy(data.member)
      _.callMap = {}
      _.callList.forEach((uid) => {
        let isCalling = uid == this.userInfo.uid
        _.callMap[uid] = new _call.CallMember({
          type: isCalling ? 'Calling' : 'Join',
          uid: uid,
          video: data.video,
          audio: data.audio,
        })
      })
      _call.room = new _call.CallRoom(_)
      return true
    },

    findUsers(users) {
      return moreUser({ uids: JSON.stringify(users) }).then((res) => {
        if (Array.isArray(res.data)) {
          res.data.forEach((e) => {
            e.userId = e.id
            e.uid = e.id
            e.avatar = this.avatarHandler(e.avatar)
            e.displayName = e.nickname || e.name
            this.usersMap[e.id] = e
          })
          this.$store.state.user.map = this.$copy(this.usersMap)
        }
      })
    },

    isInsideChina(coordinate) {
      return window.chinaPolygon.containsPoint(coordinate)
    },

    async lonlatHandler(e) {
      ;['longitude', 'latitude'].forEach((key) => {
        if (!e[key] || e[key] === 'null' || e[key] === '0.0') {
          e[key] = 0
          return
        }
        e[key] += ''
        const arr = e[key].split('.')
        if (Array.isArray(arr) && arr.length === 2) {
          arr[1] = (arr[1] + lonlatSliceEnd).slice(0, lonlatSliceLen)
          e[key] = arr.join('.') * 1
        } else {
          e[key] = 0
        }
      })
      let [lng, lat] = window.gcj02towgs84([e.longitude, e.latitude])
      var maptalks = await $app.$defers.maptalks.promise
      if (!window.chinaPolygon) {
        window.chinaPolygon = new maptalks.Polygon(ChinaPolygon, {
          symbol: chinaPolygonConfig,
        }).addTo(window.chinaPolygonLayer)
        window.coordinate0 = new maptalks.Coordinate([0, 0])
      }
      if (!e.coordinate) {
        e.coordinate = new maptalks.Coordinate([lng, lat])
      }
      if (!$app.isInsideChina(e.coordinate)) {
        lng = lat = 0
        e.coordinate = window.coordinate0
      }
      e.longitude = lng
      e.latitude = lat
      if (!e.position) {
        e.position = [lng, lat]
      }
      if (!e.key) {
        e.key = lng + ',' + lat
      }
      if (!e.address || e.address == 'null') {
        e.address = ''
      }
      if (e.uri) {
        let uriSplit = e.uri.split('/')
        e._fileName = uriSplit[uriSplit.length - 1]
      }
      return e
    },

    lonlatHandler2(e, maptalks) {
      let position = null
      if (e.longitude) {
        position = [Number(e.longitude), Number(e.latitude)]
      }
      ;['longitude', 'latitude'].forEach((key) => {
        if (!e[key] || e[key] === 'null' || e[key] === '0.0') {
          e[key] = 0
          return
        }
        e[key] += ''
        const arr = e[key].split('.')
        if (Array.isArray(arr) && arr.length === 2) {
          arr[1] = (arr[1] + lonlatSliceEnd).slice(0, lonlatSliceLen)
          e[key] = arr.join('.') * 1
        } else {
          e[key] = 0
        }
      })
      let [lng, lat] = window.gcj02towgs84([e.longitude, e.latitude])
      if (!window.chinaPolygon) {
        window.chinaPolygon = new maptalks.Polygon(ChinaPolygon, {
          symbol: chinaPolygonConfig,
        }).addTo(window.chinaPolygonLayer)
        window.coordinate0 = new maptalks.Coordinate([0, 0])
      }
      if (!e.coordinate) {
        e.coordinate = new maptalks.Coordinate([lng, lat])
      }
      e.isMap = 1
      if (!e.coordinate.x || !e.coordinate.y || !$app.isInsideChina(e.coordinate)) {
        e.isMap = 0
        lng = lat = 0
        e.coordinate = window.coordinate0
      }
      e.longitude = lng
      e.latitude = lat
      if (!e.position) {
        e.position = [lng, lat]
      }
      if (!e.address || e.address == 'null') {
        e.address = ''
      }
      if (e.uri) {
        let uriSplit = e.uri.split('/')
        e._fileName = uriSplit[uriSplit.length - 1]
      }
      if (e.longitude) {
        e.longitude = position[0]
        e.latitude = position[1]
        e.position[0] = position[0]
        e.position[1] = position[1]
      }
      return e
    },

    loadVideojs() {
      if (!window.videojs) {
        return import('video.js').then((videojs) => {
          import('video.js/dist/video-js.min.css')
          videojs.default.addLanguage('zh-CN', {
            Play: '播放',
            Pause: '暂停',
            'Current Time': '当前时间',
            Duration: '时长',
            'Remaining Time': '剩余时间',
            'Stream Type': '媒体流类型',
            LIVE: '直播',
            Loaded: '加载完成',
            Progress: '进度',
            Fullscreen: '全屏',
            'Exit Fullscreen': '退出全屏',
            'Picture-in-Picture': '画中画',
            'Exit Picture-in-Picture': '退出画中画',
            Mute: '静音',
            Unmute: '开启音效',
            'Playback Rate': '播放速度',
            Subtitles: '字幕',
            'subtitles off': '关闭字幕',
            Captions: '内嵌字幕',
            'captions off': '关闭内嵌字幕',
            Chapters: '节目段落',
            'Close Modal Dialog': '关闭弹窗',
            Descriptions: '描述',
            'descriptions off': '关闭描述',
            'Audio Track': '音轨',
            'You aborted the media playback': '视频播放被终止',
            'A network error caused the media download to fail part-way.':
              '网络错误导致视频下载中途失败。',
            'The media could not be loaded, either because the server or network failed or because the format is not supported.':
              '视频因格式不支持或者服务器或网络的问题无法加载。',
            'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.':
              '由于视频文件损坏或是该视频使用了你的浏览器不支持的功能，播放终止。',
            'No compatible source was found for this media.': '无法找到此视频兼容的源。',
            'The media is encrypted and we do not have the keys to decrypt it.':
              '视频已加密，无法解密。',
            'Play Video': '播放视频',
            Close: '关闭',
            'Modal Window': '弹窗',
            'This is a modal window': '这是一个弹窗',
            'This modal can be closed by pressing the Escape key or activating the close button.':
              '可以按ESC按键或启用关闭按钮来关闭此弹窗。',
            ', opens captions settings dialog': ', 开启标题设置弹窗',
            ', opens subtitles settings dialog': ', 开启字幕设置弹窗',
            ', opens descriptions settings dialog': ', 开启描述设置弹窗',
            ', selected': ', 选择',
            'captions settings': '字幕设定',
            'Audio Player': '音频播放器',
            'Video Player': '视频播放器',
            Replay: '重新播放',
            'Progress Bar': '进度条',
            'Volume Level': '音量',
            'subtitles settings': '字幕设定',
            'descriptions settings': '描述设定',
            Text: '文字',
            White: '白',
            Black: '黑',
            Red: '红',
            Green: '绿',
            Blue: '蓝',
            Yellow: '黄',
            Magenta: '紫红',
            Cyan: '青',
            Background: '背景',
            Window: '窗口',
            Transparent: '透明',
            'Semi-Transparent': '半透明',
            Opaque: '不透明',
            'Font Size': '字体尺寸',
            'Text Edge Style': '字体边缘样式',
            None: '无',
            Raised: '浮雕',
            Depressed: '压低',
            Uniform: '均匀',
            Dropshadow: '下阴影',
            'Font Family': '字体库',
            'Proportional Sans-Serif': '比例无细体',
            'Monospace Sans-Serif': '单间隔无细体',
            'Proportional Serif': '比例细体',
            'Monospace Serif': '单间隔细体',
            Casual: '舒适',
            Script: '手写体',
            'Small Caps': '小型大写字体',
            Reset: '重置',
            'restore all settings to the default values': '恢复全部设定至预设值',
            Done: '完成',
            'Caption Settings Dialog': '字幕设定窗口',
            'Beginning of dialog window. Escape will cancel and close the window.':
              '打开对话窗口。Escape键将取消并关闭对话窗口',
            'End of dialog window.': '结束对话窗口',
            'Seek to live, currently behind live': '尝试直播，当前为延时播放',
            'Seek to live, currently playing live': '尝试直播，当前为实时播放',
            'progress bar timing: currentTime={1} duration={2}': '{1}/{2}',
            '{1} is loading.': '正在加载 {1}。',
            'No content': '无内容',
            Color: '颜色',
            Opacity: '不透明度',
            'Text Background': '文本背景',
            'Caption Area Background': '字幕区域背景',
            'Skip forward {1} seconds': '快进 {1} 秒',
            'Skip backward {1} seconds': '快退 {1} 秒',
          })
          return (window.videojs = videojs.default)
        })
      } else {
        return window.videojs
      }
    },
  },
}
