/**
 * 状态管理模块的状态
 * @type {Object}
 * @property {number} model - 当前模型状态
 * @property {Object|null} channel - 当前频道信息
 * @property {Object|null} auth - 当前认证信息
 */
const state = {
  model: 0,
  channel: null,
  auth: null,
}
const mutations = {}
let player, video

/**
 * 添加事件监听器到播放器
 * @function addEvents
 */
function addEvents() {
  // 监听 ICE 协商出错事件
  player.on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR, (e) => {
    // ICE 协商出错
    console.debug('>>> shout ICE 协商出错')
    console.debug(e)
  })

  // 监听远端流播放成功事件
  player.on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS, (e) => {
    // 获取到了远端流，可以播放
    console.debug('>>> shout 播放成功')
    console.debug(e)
  })

  // 监听 offer answer 交换失败事件
  player.on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED, (e) => {
    // offer anwser 交换失败
    console.debug('>>> shout offer anwser 交换失败')
    console.debug(e)
    stop()
  })

  // 监听本地流获取事件
  player.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, (s) => {
    // 获取到了本地流
    console.debug('>>> shout 获取到了本地流')
    console.debug(s)
  })

  // 监听本地流获取失败事件
  player.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, (s) => {
    // 获取本地流失败
    $app.$message.error('获取本地流失败')
    console.debug('>>> shout 获取本地流失败')
    console.debug(s)
  })

  // 监听 RTC 状态变化事件
  player.on(ZLMRTCClient.Events.WEBRTC_ON_CONNECTION_STATE_CHANGE, (state, event) => {
    // RTC 状态变化 ,详情参考 https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionState
    console.debug('>>> shout RTC 状态变化', state, event)
    if (state === 'connected') {
      $app.$store.dispatch('shout/start')
    }
  })

  // 监听数据通道打开事件
  player.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_OPEN, (event) => {
    console.debug('>>> shout rtc datachannel 打开 :', event)
  })

  // 监听数据通道消息事件
  player.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_MSG, (event) => {
    console.debug('>>> shout rtc datachannel 消息 :', event)
  })

  // 监听数据通道错误事件
  player.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_ERR, (event) => {
    console.debug('>>> shout rtc datachannel 错误 :', event)
  })

  // 监听数据通道关闭事件
  player.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_CLOSE, (event) => {
    console.debug('>>> shout rtc datachannel 关闭 :', event)
  })
}

/**
 * 停止播放器并清理资源
 * @function stop
 */
function stop() {
  if (player) {
    player.close() // 关闭播放器
    player = null // 清空播放器引用
  }
  if (video && video.srcObject) {
    video.srcObject = null // 清空视频源对象
    video.load() // 重新加载视频元素
  }
}

/**
 * 在播放器中进行音视频流的播放
 * @async
 * @function play
 * @param {Object} context - Vuex 上下文
 * @param {Object} params - 播放参数
 * @param {Object} params.channel - 频道信息
 * @param {Object} params.auth - 认证信息
 */
async function play(
  { commit, dispatch, getters, rootGetters, rootState, state },
  { channel, auth },
) {
  stop() // 停止当前播放
  let resolution // = await ZLMRTCClient.GetSupportCameraResolutions()
  resolution = resolution ? resolution[0] : undefined // 获取分辨率

  let option = {
    element: video, // 视频元素
    recvOnly: false, // 是否只接收
    audioEnable: true, // 启用音频
    videoEnable: false, // 启用视频
    useCamera: false, // 是否使用摄像头
    usedatachannel: false, // 是否使用数据通道
    simulcast: false, // 是否启用多路复用
    debug: true, // 启用调试模式
    resolution: resolution ? { w: resolution.width, h: resolution.height } : { w: 0, h: 0 }, // 设置分辨率
    type: 'shout', // 类型
    playParam: {
      app: auth.app, // 应用信息
      sign: auth.sign, // 签名信息
      streamId: auth.stream, // 流 ID
      type: auth.type, // 类型
    },
  }

  player = new ZLMRTCClient.Endpoint(option) // 创建播放器实例
  addEvents() // 添加事件监听器
}

function beforeCheck() {
  stop()
}

const actions = {
  /**
   * 设置频道和模式
   * @function setup
   * @param {Object} context - Vuex 上下文
   * @param {Object} params - 设置参数
   * @param {Object} params.channel - 频道信息
   * @param {string} params.mode - 模式
   * @returns {Promise} - 返回服务请求的 Promise
   */
  setup({ commit, dispatch, getters, rootGetters, rootState, state }, { channel, mode }) {
    if (!video) {
      video = $app.$videoEle
    }
    beforeCheck()

    let params = {
      channelId: channel.channelId,
      deviceId: channel.deviceId,
      hasAudio: true,
    }
    $app.$service.post(process.env.VUE_APP_API + '/gb/device/enableAudio', params)
    return $app.$service
      .post(process.env.VUE_APP_API + '/gb/device/talk/auto', {
        channelId: channel.channelId,
        deviceId: channel.deviceId,
      })
      .then(async (res) => {
        if (res && res.stream) {
          await $app.loadZLMRTCClient()
          state.channel = channel
          state.auth = res
          state.model = mode
          play({ commit, dispatch, getters, rootGetters, rootState, state }, { channel, auth: res })
        }
        return res && res.stream
      })
      .catch((e) => {
        $app.$message.info(e)
      })
  },
  /**
   * 启动频道和模式
   * @function start
   * @returns {Promise} - 返回服务请求的 Promise
   */
  start() {
    return $app.$service
      .post(process.env.VUE_APP_API + '/gb/device/talk/start', {
        channelId: $app.$store.state.shout.channel.channelId, // 频道 ID
        deviceId: $app.$store.state.shout.channel.deviceId, // 设备 ID
        streamId: $app.$store.state.shout.auth.stream, // 流 ID
        model: $app.$store.state.shout.model, // 模式
      })
      .then((res) => {
        if (res === 'OK') {
          $app.$message.info('后台喊话已开启') // 提示信息
        }
      })
      .catch((e) => e) // 错误处理
  },
  /**
   * 关闭播放器
   * @function close
   */
  close() {
    stop() // 停止播放器
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
