<template>
  <!-- <v-scale-screen width="1920" height="1080"> -->
    <div id="app">
      <router-view />
      <div v-if="loading" class="loading" v-loading="loading"></div>
    </div>
  <!-- </v-scale-screen> -->

</template>

<script setup>
import Vue, { provide, ref } from 'vue'
Vue.component('mCtn', require('@/components/layout/components/m-container.vue').default)
Vue.component('sCtn', require('@/components/layout/components/s-container.vue').default)
Vue.component('zoomFont', require('@/components/layout/components/zoom-font.vue').default)
Vue.component('abFull', require('@/components/layout/components/ab-full.vue').default)
// import VScaleScreen from 'v-scale-screen'
provide('toggleGlobalLoading', toggleGlobalLoading)

const loading = ref(false)
function toggleGlobalLoading(b) {
  loading.value = b
}
</script>

<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  color: #fff;
  font-family: 'Microsoft YaHei', '微软雅黑', 'Arial', '黑体', '宋体', sans-serif;
  @include prop(font-size, 14);
}

.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
</style>
