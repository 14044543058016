// 初始化
export const LoginPage = 'login'
export const HomePage = 'dashboard'
export const Redirect = 'redirect'
export const DefualtCity = '深圳市'
export const lonlatSliceLen = 15
export const lonlatSliceEnd = '0'.repeat(lonlatSliceLen)

// 菜单
export const Menu1 = '数字中台'
export const Menu1Path = 'dashboard'
export const Menu2 = '智慧视频'
export const Menu2Path = 'deviceCenter'
export const Menu3 = '实时地图'
export const Menu3Path = 'map'
export const Menu4 = '智慧作业'
export const Menu4Path = 'iot'
export const Menu5 = '智能算法'
export const Menu5Path = 'aiscenes'
export const Menu6 = '智慧应急'
export const Menu6Path = 'mapDigital'
export const Menu7 = '智慧变电'
export const Menu7Path = 'bdModule'

// 地图
export const FenceKey = 'fence'
export const FenceLabel = '电子围栏'
export const TrackKey = 'track'
export const TrackLabel = '地图轨迹'
export const CollectionKey = 'collection'
export const CollectionLabel = '智慧相册'
export const DeviceKey = 'device'
export const DeviceLabel = '设备地图'
export const XunjianKey = 'xunjian'
export const XunjianLabel = '智慧巡检'
export const powerStationDotKey = 'powerStationDot'
export const powerStationDotLabel = '电场落点'
export const positioningBoxKey = 'PositioningBox'
export const positioningBoxLabel = '应急装备一张图'
export const smartSiteKey = 'smartSite'
export const smartSiteLabel = '智慧工地'

export const MapFenceModeAdd = 'add'
export const MapFenceModeEdit = 'edit'

// 智慧作业
export const IotData = 'index'
export const IotDataLabel = '数据统计'
export const IotDevice = 'device'
export const IotDeviceLabel = '数据监控'
export const IotRadar = 'radar'
export const IotRadarLabel = '智慧探测'
export const IotSummary = 'summary'
export const IotSummaryLabel = '数据汇总'
export const IotSmartLighting = 'smartLighting'
export const IotSmartLightingLabel = '智慧光照'


// eventNames
export const MaptalksClearMapMainLayer = 'MaptalksClearMapMainLayer'
export const MaptalksClearMapClusterLayer = 'MaptalksClearMapClusterLayer'
export const MaptalksMapDrawToolToggle = 'MaptalksMapDrawToolToggle'
export const MaptalksDrawGeometryToggle = 'MaptalksDrawGeometryToggle'
export const MaptalksShowPolygon = 'MaptalksShowPolygon'
export const MaptalksMove2Polygon = 'MaptalksMove2Polygon'
export const MaptalksFenceEditableToggle = 'MaptalksFenceEditableToggle'
export const MaptalksRoutePlayerSetup = 'MaptalksRoutePlayerSetup'
export const MaptalksRoutePlayerBarToggle = 'MaptalksRoutePlayerBarToggle'
export const MaptalksCreateMapClusterItemUiMarker = 'MaptalksCreateMapClusterItemUiMarker'

export const MaptalksMapSetCenterAndZoom = 'MaptalksMapSetCenterAndZoom'
export const MaptalksMapHideAll = 'MaptalksMapHideAll'
export const MaptalksMapShowAll = 'MaptalksMapShowAll'
export const MaptalksMapClearMapUIMarkers = 'MaptalksMapClearMapUIMarkers'

export const ApiV3DeviceMapDeviceList2DeviceMapVersion = 7
