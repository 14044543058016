import { render, staticRenderFns } from "./ui-button.vue?vue&type=template&id=5f9dc6e0&scoped=true"
import script from "./ui-button.vue?vue&type=script&lang=js"
export * from "./ui-button.vue?vue&type=script&lang=js"
import style0 from "./ui-button.vue?vue&type=style&index=0&id=5f9dc6e0&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f9dc6e0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\code\\公网环境\\gmd-top-platform-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f9dc6e0')) {
      api.createRecord('5f9dc6e0', component.options)
    } else {
      api.reload('5f9dc6e0', component.options)
    }
    module.hot.accept("./ui-button.vue?vue&type=template&id=5f9dc6e0&scoped=true", function () {
      api.rerender('5f9dc6e0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ui/ui-button.vue"
export default component.exports